// Polyfills
import 'promise-polyfill/src/polyfill';

// NPM packages
import lozad from 'lozad';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import CoolLightBox from 'vue-cool-lightbox';
import Swiper from 'swiper';
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {required, email, ext, size, regex} from 'vee-validate/dist/rules';
import vueSmoothScroll from 'vue2-smooth-scroll';

import store from "./store.js";


// Lozad
window.lozadObserver = lozad('.lozad', {
    loaded: el => {
        const cropPos = el.getAttribute('data-crop-pos');
        if (cropPos) {
            const img = el.getElementsByTagName('img');
            if (img) img[0].style.objectPosition = cropPos;
        }
        el.classList.add('loaded');
    }
});

// Import our CSS
import styles from '../css/app.pcss';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

// App main
const main = async () => {
    // Async load the vue module
    const {default: Vue} = await import(/* webpackChunkName: "vue" */ 'vue');
    const {default: axios} = await import( /* webpackChunkName: "axios" */ 'axios');
    const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');

    //VEE VALIDATE
    extend('required', {
        ...required,
        message: 'Dit veld is vereist'
    });

    extend('email', {
        ...email,
        message: 'Dit is geen geldig email adres'
    });
    extend('regex', {
        ...regex,
        message: 'Gelieve de juiste formatering te gebruiken'
    });
    extend('validpostal', val => {
        if (val.length == 4 && /^\d+$/.test(val)) return true;
        return 'Gelieve een geldige postcode in te vullen';
    });
    extend('ext', {
        ...ext,
        message: 'De toegelaten bestandsextensies zijn: JPG, JPEG, PNG.'
    });
    extend('size', {
        ...size,
        message: 'De maximale toegelaten bestandsgrootte is 5MB.'
    });
    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);

    Vue.use(VueCookies);
    Vue.use(CoolLightBox);
    Vue.use(vueSmoothScroll);

    window.axios = axios;

    // Set CSRF token
    const csrfToken = document.head.querySelector('meta[name="csrf-token"]')
    if (csrfToken) {
        window.axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken.content;//
        window.axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    } else {
        console.error('CSRF token not found.')
    }

    // MapBox
    window.mapBoxToken = 'pk.eyJ1IjoidGhlY3JhZnQiLCJhIjoiY2tidW9oOWlxMDVlZjJ1cGFpY2Z5b2ZkeSJ9.LmXnhbbSeJdY3VwdF0jB4Q';
    window.maps = [];

    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        store,
        delimiters: ['${', '}'],
        components: {
            'nav-anchors': () => import(/* webpackChunkName: "nav-anchors" */ '../vue/NavAnchors.vue'),
            'frequently-asked-questions': () => import(/* webpackChunkName: "frequently-asked-questions" */ '../vue/FrequentlyAskedQuestions.vue'),
            'accordion-block': () => import(/* webpackChunkName: "accordion-block" */ '../vue/AccordionBlock.vue'),
            'register-activity': () => import(/* webpackChunkName: "register-activity" */ '../vue/account/RegisterActivity.vue'),
            'blog-list': () => import(/* webpackChunkName: "blog-list" */ '../vue/blog/BlogList.vue'),
            'news-list': () => import(/* webpackChunkName: "news-list" */ '../vue/news/NewsList.vue'),
            'projects-list': () => import(/* webpackChunkName: "news-list" */ '../vue/projects/ProjectsList.vue'),
            'events-list': () => import(/* webpackChunkName: "events-list" */ '../vue/events/EventsList.vue'),
            'activities-filter': () => import(/* webpackChunkName: "activities-filter" */ '../vue/activities/ActivitiesFilter.vue'),
            'activities-list': () => import(/* webpackChunkName: "activities-list" */ '../vue/activities/ActivitiesList.vue'),
            'login-form': () => import(/* webpackChunkName: "dashboard" */ '../vue/account/_inc/LoginForm.vue'),
            'dashboard': () => import(/* webpackChunkName: "dashboard" */ '../vue/account/Dashboard.vue'),
            'activity-form': () => import(/* webpackChunkName: "edit-activity" */ '../vue/account/_inc/ActivityForm.vue'),
            'edit-user-page': () => import(/* webpackChunkName: "edit-user-page" */ '../vue/account/EditUserPage.vue'),
            'osm-map': () => import(/* webpackChunkName: "osm-map" */ '../vue/OSMMap.vue'),
            //'hubspot': () => import(/* webpackChunkName: "edit-user-page" */ '../vue/HubSpot.vue'),
        },

        data: {
            // Navigation
            activeNavElementId: null,
            activeSegments: [],
            mobileNavIsOpen: false,
            hideMobileNav: false,
            lastScrollTop: 0,
            fixateNav: false,

            // Sub navigation
            scrollLabels: [],

            // Lightbox
            lightboxIndex: null,
            lightboxItems: [],
            showVideoModal: false,

            // Status bar
            showStatusBar: true,

            // Other
            smoothScrollConfig: {
                duration: 500,
                offset: -160,
                container: window,
                updateHistory: false
            },

            // Search results
            searchResultsActiveTab: 'pages',

            //interruptions map
            interruptionLocation: '',
            mapError: false
        },

        created() {
            window.addEventListener('scroll', this.onWindowScroll);
            window.addEventListener('resize', this.onWindowResize);
        },

        mounted() {
            // Init formie
            if (window.Formie) {
                window.Formie.initForms();
            }

            // Navigation active state
            if (window.activeNavElementId) this.activeNavElementId = window.activeNavElementId;
            const strSegments = window.location.pathname;
            this.activeSegments = strSegments.split('/').slice(1); // First segment is empty

            // Lozad
            window.lozadObserver.observe();

            // Scroll labels Nav Anchors
            if (window.scrollLabels) this.scrollLabels = JSON.parse(window.scrollLabels.replace(/&quot;/g, '"').replace(/&#039;/g, '\''));

            // Init blocks
            this.initSwiper('block-instagram', {
                slidesPerView: 1.2,
                loop: true,
                breakpoints: {
                    768: {
                        slidesPerView: 3
                    }
                }
            });

            this.initSwiper('block-images', {
                slidesPerView: 1.5,
                breakpoints: {
                    768: {
                        slidesPerView: 3
                    },
                    1024: {
                        slidesPerView: 4
                    }
                }
            });

            this.initSwiper('block-news-carrousel', {
                slidesPerView: 1.2,
                breakpoints: {
                    768: {
                        slidesPerView: 3
                    },
                    1280: {
                        slidesPerView: 4
                    }
                }
            });

            this.initSwiperImagesProject();

            // Maps
            this.initMaps();

            this.initInterruptionMap();

            this.onWindowScroll();
            this.calcVideoPopUpSize();

            // Search
            this.makeSearchTabWithDataActive();
        },

        beforeDestroy() {
            window.removeEventListener('scroll', this.onWindowScroll);
            window.removeEventListener('resize', this.onWindowResize);
        },

        methods: {
            onWindowScroll() {
                /*Nav*/
                let scrollTop = window.pageYOffset;
                let navHeight = 0;

                if (this.$refs.navExtras) navHeight = this.$refs.navExtras.clientHeight;

                this.fixateNav = window.pageYOffset > navHeight;
                this.repositionAnchorsNav();

                if (!this.mobileNavIsOpen) {
                    this.hideMobileNav = (scrollTop > this.lastScrollTop) && (scrollTop > navHeight);
                    this.lastScrollTop = scrollTop;
                }

                /*image skeletons*/
                document.querySelectorAll('.img-skeleton .lozad.loaded').forEach(loadedImg => {
                    loadedImg.parentElement.classList.remove('img-skeleton');
                })
            },

            onWindowResize() {
                this.repositionAnchorsNav();
                this.calcVideoPopUpSize();
            },
    
            calcVideoPopUpSize() {
                const blockVideoPopUp = this.$refs.blockVideoPopUp
                if (blockVideoPopUp) {
                    const videoAspectRatioName = blockVideoPopUp.dataset.aspectRatio
                    let aspectRatioX = 16
                    let aspectRatioY = 9
                    switch (videoAspectRatioName) {
                        case 'panorama':
                            aspectRatioX = 256
                            aspectRatioY = 81
                            break
                        case 'default':
                            aspectRatioX = 4
                            aspectRatioY = 3
                            break
                    }
    
                    const iframe = blockVideoPopUp.querySelector('iframe')
                    if (iframe) {
                        const vwOrientation = window.innerWidth / window.innerHeight > aspectRatioX / aspectRatioY
                        const padding = 50; // px
                        if (vwOrientation) {
                            // viewport landscape
                            let h = Math.ceil(window.innerHeight - padding);
                            let w = Math.ceil(aspectRatioX / aspectRatioY * h)
                            iframe.height = h + 'px'
                            iframe.width = w + 'px'
                        } else {
                            // viewport portrait
                            let w = Math.ceil(window.innerWidth - padding);
                            let h = Math.ceil(w / aspectRatioX * aspectRatioY)
                            iframe.height = h + 'px'
                            iframe.width = w + 'px'
                        }
                    }
                }
            },
    
            toggleVideoModal() {
                this.showVideoModal = !this.showVideoModal;
                this.showVideoModal ? disableBodyScroll(this.$refs.blockVideoPopUp) : enableBodyScroll(this.$refs.blockVideoPopUp);
            },

            repositionAnchorsNav() {
                const anchorWrapper = document.getElementById('anchors-wrapper');
                const navMain = document.getElementById('nav-main');

                // Timeout because nav height not calculated soon enough when position fixed
                if (navMain && anchorWrapper) {
                    setTimeout(() => {
                        anchorWrapper.style.top = navMain.clientHeight - 1 + 'px';
                    }, 200);
                }
            },

            toggleMobileNav() {
                this.mobileNavIsOpen = !this.mobileNavIsOpen;
                this.mobileNavIsOpen ? disableBodyScroll(this.$refs.navMobile) : enableBodyScroll(this.$refs.navMobile);
            },

            showLightbox(data, index) {
                this.lightboxIndex = index || 0;
                this.lightboxItems = data;
            },

            //This is the action when the register-form email field is filled in.
            //We will then redirect them to the real register-form template with the email as param
            startRegisterProcess($event) {
                let formData = new FormData($event.target);
                //fallback redirect
                let redirectUrl = '/user/register?email=';
                if (formData.get('email') !== undefined && formData.get('redirect') !== undefined) {
                    redirectUrl = formData.get('redirect') + '?email=' + formData.get('email');
                }
                window.location.href = redirectUrl;
            },

            initSwiper(className, swiperOptions) {
                const blockEl = document.querySelectorAll(`.${className}`);

                for (let block of blockEl) {
                    let swipeEl = (block.getElementsByClassName('swiper-container').length) ? block.getElementsByClassName('swiper-container')[0] : false;
                    if (swipeEl) {
                        const btnNext = (block.getElementsByClassName('swiper-next').length) ? block.getElementsByClassName('swiper-next')[0] : false;
                        const btnPrev = (block.getElementsByClassName('swiper-prev').length) ? block.getElementsByClassName('swiper-prev')[0] : false;

                        new Swiper(swipeEl, {
                            ...swiperOptions,
                            spaceBetween: 23,
                            speed: 1000,
                            watchOverflow: true,
                            autoplay: {
                                delay: 5000,
                                disableOnInteraction: false
                            },
                            navigation: {
                                nextEl: btnNext,
                                prevEl: btnPrev,
                            }
                        });
                    }
                }
            },

            initSwiperImagesProject() {
                const blockEl = document.querySelectorAll('.block-images--project');

                for (let block of blockEl) {
                    let swipeEl = (block.getElementsByClassName('swiper-container').length) ? block.getElementsByClassName('swiper-container')[0] : false;
                    if (swipeEl) {
                        const btnNext = (block.getElementsByClassName('swiper-next').length) ? block.getElementsByClassName('swiper-next')[0] : false;
                        const btnPrev = (block.getElementsByClassName('swiper-prev').length) ? block.getElementsByClassName('swiper-prev')[0] : false;

                        new Swiper(swipeEl, {
                            slidesPerView: 1.2,
                            spaceBetween: 23,
                            speed: 1000,
                            watchOverflow: true,
                            navigation: {
                                nextEl: btnNext,
                                prevEl: btnPrev,
                            },
                            breakpoints: {
                                768: {
                                    slidesPerView: 1,
                                    centeredSlides: true,
                                }
                            }
                        });
                    }
                }
            },

            closeStatusBar(slug) {
                VueCookies.set(`status-${slug}`, 'hidden', '7d');
                this.showStatusBar = false;
            },

            initInterruptionMap() {
                let imaps = document.querySelectorAll('.i-map')
                let coordinates = null;
                if (imaps.length) {
                    imaps.forEach(element => {
                        mapboxgl.accessToken = window.mapBoxToken;

                        function initMap () {

                        }
                        const map = new mapboxgl.Map({
                            container: element,
                            /*style: 'mapbox://styles/thecraft/ckbupp1dt0ptf1iqjbhj2fksr',*/
                            style: {
                                version: 8,
                                sources: {
                                    osm: {
                                        type: 'raster',
                                        tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
                                        tileSize: 256,
                                    }
                                },
                                layers: [{
                                    id: 'osm',
                                    type: 'raster',
                                    source: 'osm',
                                }],
                            },
                            center: [4.402464, 51.219448], // starting position [lng, lat]
                            zoom: 9 // starting zoom
                        });

                        /*Remove double click*/
                        map.doubleClickZoom.disable();

                        // Add nav controls to map
                        let nav = new mapboxgl.NavigationControl();
                        map.addControl(nav, 'top-left');

                        // Add search bar to map
                        map.addControl(
                            new MapboxGeocoder({
                                accessToken: mapboxgl.accessToken,
                                mapboxgl: mapboxgl,
                                marker: false
                            })
                        );

                        /*let locationInput = document.getElementsByName('fields[location]')*/
                        let lngInput = document.getElementsByName('fields[lng]')
                        let latInput = document.getElementsByName('fields[lat]')
                        let marker = new mapboxgl.Marker({
                            color: '#958DD8',
                            draggable: true
                        });

                        map.on('click', function (event) {
                            coordinates = event.lngLat;
                            marker.setLngLat(coordinates).addTo(map);

                            if (lngInput && latInput) {
                                lngInput.forEach(input => {
                                    input.value = `${coordinates.lng}`;
                                })
                                latInput.forEach(input => {
                                    input.value = `${coordinates.lat}`;
                                })
                            }
                        });
                    })
                }
            },

            initMaps() {
                //MAPBOX ACTIVITY DETAIL
                let maps = document.querySelectorAll('.map')
                // Do we have a map?
                if (maps.length) {
                    maps.forEach(element => {
                        const lat = element.getAttribute('data-lat')
                        const lng = element.getAttribute('data-lng')
                        const icon = element.getAttribute('data-icon')

                        if (lat && lng) {
                            // Set MapBox token
                            mapboxgl.accessToken = window.mapBoxToken;

                            // Initiate maps
                            const map = new mapboxgl.Map({
                                container: element,
                                style: 'mapbox://styles/thecraft/ckbupp1dt0ptf1iqjbhj2fksr',
                                center: [lng, lat],
                                zoom: 16,
                            }).addControl(
                                new mapboxgl.NavigationControl({
                                    showCompass: false,
                                    showZoom: true
                                })
                            )

                            if (icon) {
                                // Create marker as HTML (isn't this fucking awesome!)
                                const marker = document.createElement('div')
                                marker.className = 'marker'
                                marker.style.backgroundImage = 'url(' + icon + ')'
                                // Set marker
                                new mapboxgl.Marker(marker).setLngLat([lng, lat]).addTo(map)
                            } else {
                                // Use default marker
                                new mapboxgl.Marker({color: '#958DD8'}).setLngLat([lng, lat]).addTo(map)
                            }

                            window.maps.push(map);
                        }

                    })
                }
            },

            makeSearchTabWithDataActive() {
                /*Check if you are on the search page*/
                const searchPageTabSection = document.querySelector('.search-page .tabs')
                /*If you are on the search page find the search tabs*/
                if (searchPageTabSection) {
                    /*Get all the tabs*/
                    const allTabs = searchPageTabSection.querySelectorAll(".tab:not(.no-results)")
                    /*Find the first tab where there is content and make it active*/
                    this.searchResultsActiveTab = allTabs[0].dataset.title
                }
            },
            
        }
    });

    return vm;
};

// Execute async function
main().then((vm) => {
});

window.addEventListener("load", () => {
    resizeMaps(); // Chrome resize bug
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}

function resizeMaps() {
    window.maps.forEach(map => {
        map.resize();
    })
}
