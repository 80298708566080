import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        consentValues: null,
        loginInfo: null,
        privacyUrl: '',
        formTexts: {
            loginHeading: 'Inloggen',
            loginIntro: 'Log in met je e-mailadres',
            registerHeading: '',
            registerIntro: '',
            registerUsps: [],
            contactInfoHeader: 'Gegevens',
            chooseOrganisationHeading: 'Kies organisatie',
            parentOrganisationInfoHeading: '',
            parentOrganisationInfoDefinition: '',
            activityHeading: 'Activiteit aanmaken',
            disclaimerOptinText: 'Trage wegen heeft het recht om de ingevulde gegevens aan te passen'
        },
        steps: [
            {
                'label': 'Inloggen',
                'path': '/inloggen',
                'clickAble': true,
                'completed': false
            },
            {
                'label': 'Gegevens',
                'path': '/gegevens',
                'clickAble': false,
                'completed': false
            },
            {
                'label': 'Organisatie',
                'path': '/organisatie',
                'clickAble': false,
                'completed': false
            },
            {
                'label': 'Activiteit',
                'path': '/activiteit',
                'clickAble': false,
                'completed': false
            }
        ],
        activityLocationData: {
            uitLocationView: true,
            selectedUitLocation: {
                id: '',
                name: '',
                description: '',
                address: {
                    postalCode: '',
                    street: '',
                    number: '',
                    city: ''
                }
            },
            uitSuggestions: [],
            newAddress: null
        },
        backendFormErrors: {
            'user': [],
            'koepelorganisatie': [],
            'organisatie': [],
            'activity': [],
            'login': []
        },
        newUser: true,
        formData: {},
        apiKey: '731927cb-58f4-4e7c-b966-7c7604dbe02b',

        // Default values to reset inputs
        activitiesFiltersReset: {
            activity: '',
            location: '',
            start: null,
            end: null,
            ages: '',
            query: ''
        },
        // Activities filters
        activitiesFilters: {
            activity: '',
            location: '',
            start: new Date(),
            end: null,
            ages: '',
            query: ''
        },
        activitiesCats: {},
        agesCats: {},
        activitiesFiltered: false
    },
    mutations: {
        setPrivacyUrl(state, url) {
            state.privacyUrl = url;
        },
        setNewUser(state, data) {
            state.newUser = data;
        },
        setUserFormValuesData(state, data) {
            if (!state.formData.hasOwnProperty('user')) {
                state.formData['user'] = {
                    formatted: new FormData,
                    formValues: new FormData
                };
            }
            state.formData['user']['formValues'] = data;
        },
        setAutoLoginInfo(state, data){
          state.loginInfo = data;
        },
        setFormData(state, data) {
            if (!state.formData.hasOwnProperty(data['form'])) {
                state.formData[data['form']] = {
                    formatted: new FormData,
                    formValues: new FormData
                };
            }
            if (data['data'].hasOwnProperty('formatted') && data['data']['formatted']) {
                state.formData[data['form']]['formatted'] = data['data']['formatted'];
            }
            if (data['data'].hasOwnProperty('formValues') && data['data']['formValues']) {
                state.formData[data['form']]['formValues'] = data['data']['formValues'];
            }
        },
        addKeyToFormData(state, data) {
            if (!state.formData.hasOwnProperty(data['form'])) {
                state.formData[data['form']] = {
                    formatted: new FormData,
                    formValues: new FormData
                };
            }
            if (data.key !== undefined && data.value !== undefined) {
                state.formData[data['form']]['formValues'].set(data.key, data.value);
            }
        },
        setActiveSteps(state, data) {
            if (typeof data == 'object' && data.length) {
                state.steps.forEach((step, index) => {
                    let stepNumber = index + 1;
                    step.clickAble = data.includes(stepNumber);
                });
            }
        },
        setStepStatus(state, data) {
            if (data.step !== 'undefined' && data.value !== 'undefined') {
                state.steps.map(el => {
                    if (el.label.toLowerCase() === data.step.toLowerCase()) {
                        el.completed = data.value;
                    }
                    return el;
                });
            }
        },
        setBackendFormErrors(state, data) {
            Vue.set(state.backendFormErrors, data['formType'], data['errors']);
        },
        clearBackendFormErrorsByHandle(state, data) {
            if (data in state.backendFormErrors) {
                Vue.set(state.backendFormErrors, data['formType'], {});
            }
        },
        setActivitiesFilters(state, filters) {
            state.activitiesFilters = filters;
        },
        removeActivitiesFilter(state, filterKey) {
            state.activitiesFilters[filterKey] = state.activitiesFiltersReset[filterKey];
        },
        setActivitiesFiltered(state, payload) {
            state.activitiesFiltered = payload;
        },
        setActivitiesFiltersByKey(state, {key, value}) {
            state.activitiesFilters[key] = value;
        },
        setActivitiesCats(state, categories) {
            state.activitiesCats = categories;
        },
        setAgesCats(state, categories) {
            state.agesCats = categories;
        },
        setFormTexts(state, payload) {
            state.formTexts = payload;
        },
        setConsentValues(state, payload) {
            state.consentValues = payload;
        }
    },
    actions: {
        setStepStatus({commit, state}, payload) {
            commit('setStepStatus', payload);
        },
        setPrivacyUrl({commit, state}, payload) {
            commit('setPrivacyUrl', payload);
        },
        setUserFormValuesDataFromArray({commit, state}, payload) { //set field values when user was logged in
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                for (const [key, val] of Object.entries(payload)) {
                    // check if the property/key is defined in the object itself, not in parent
                    if (val && payload.hasOwnProperty(key)) {
                        formData.append(key, val);
                    }
                }
                commit('setUserFormValuesData', formData);
                resolve();
            });
        },
        setNewUser({commit, state}, payload) {
            commit('setNewUser', payload);
        },
        setAutoLoginInfo({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                commit('setAutoLoginInfo', payload);
                resolve();
            });
        },
        addKeyToFormData({commit, state}, payload) { //payload = {form: 'register, 'key: 'email', value: 'e@mail.com'}
            return new Promise((resolve, reject) => {
                commit('addKeyToFormData', payload);
                resolve();
            });
        },
        // addKeyToUserData({ commit, state }, payload){ //payload = {key: 'email', value: 'e@mail.com'}
        //     commit('addKeyToUserData', payload);
        // },
        setFormData({commit, state}, payload) { //saves both formvalues & formatted for craft (address) must be FormData type
            return new Promise((resolve, reject) => {
                commit('setFormData', payload);
                resolve();
            });
        },
        setActiveSteps({commit, state}, payload) {
            commit('setActiveSteps', payload);
        },
        setBackendFormErrors({commit, state}, payload) {
            commit('setBackendFormErrors', payload);
        },
        clearBackendFormErrorsByHandle({commit, state}, payload) {
            commit('clearBackendFormErrorsByHandle', payload);
        },
        removeActivitiesFilter({commit}, filterKey) {
            commit('removeActivitiesFilter', filterKey)
        },
        setActivitiesFiltered({commit}, payload) {
            commit('setActivitiesFiltered', payload);
        },
        setActivitiesFiltersByKey({commit}, payload) {
            commit('setActivitiesFiltersByKey', payload);
        },
        setActivitiesCats({commit}, categories) {
            commit('setActivitiesCats', categories);
        },
        setAgesCats({commit}, categories) {
            commit('setAgesCats', categories);
        },
        setFormTexts({commit}, payload) {
            commit('setFormTexts', payload);
        },
        setConsentValues({commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('setConsentValues', payload);
                resolve();
            });
        }
    },
    getters: {
        getPrivacyUrl(state) {
            return state.privacyUrl;
        },
        getDataAsObject: (state) => (formType) => {
            if (!state.formData.hasOwnProperty(formType)) {
                return {};
            }
            let dataToReturn = {};
            for (var pair of state.formData[formType]['formValues'].entries()) {
                dataToReturn[pair[0]] = pair[1];
            }
            return dataToReturn;
        },
        getValidatedFormValues: (state) => (formType) => {
            let dataToReturn = {};
            if (!(formType in state.formData) || typeof state.formData[formType]['formatted'] === 'undefined' || !state.formData[formType]['formatted']) {
                return false;
            }
            for (var pair of state.formData[formType]['formatted'].entries()) {
                dataToReturn[pair[0]] = pair[1];
            }
            return dataToReturn;
        },
        getIsNewUser(state) {
            return state.newUser;
        },
        getSteps(state) {
            return state.steps;
        },
        getApiKey(state) {
            return state.apiKey;
        },
        getBackendFormErrors: (state) => (formType, fieldHandle) => {
            if (state.backendFormErrors.hasOwnProperty(formType)) {
                if (formType in state.backendFormErrors && fieldHandle in state.backendFormErrors[formType]) {
                    if (typeof state.backendFormErrors[formType][fieldHandle] == 'string') {
                        return state.backendFormErrors[formType][fieldHandle];
                    } else if ( Array.isArray(state.backendFormErrors[formType][fieldHandle].isArray)) {
                        return state.backendFormErrors[formType][fieldHandle].split(', ');
                    } else if (typeof state.backendFormErrors[formType][fieldHandle] === 'object') {
                        let err = '';
                        for (const errItem of Object.values(state.backendFormErrors[formType][fieldHandle])){
                            if(err != ''){
                                err+= ', ';
                            }
                            err += errItem;
                        }
                        return err;
                    }
                }
            }
            return false;
        },
        getActiveStepPath(state) {
            let activeStep = '';
            Object.keys(state.steps).forEach(el => {
                let step = state.steps[el];
                if (step.label.toLowerCase() !== 'inloggen' && activeStep === '') {
                    if (!step.completed) {
                        activeStep = step['path'];
                    }
                }
            });
            if (activeStep === '') {
                activeStep = '/activiteit'; //if all completed
            }
            return activeStep;

        },
        getActivityLocationData(state) {
            return state.activityLocationData;
        },
        getFormTextByHandle: (state) => (handle) => {
            if (!(handle in state.formTexts)) {
                return '';
            }
            return state.formTexts[handle];
        },
        getConsentValues(state) {
            return state.consentValues;
        },
        getLoginInfo(state) {
            return state.loginInfo;
        }
    }
})
;
